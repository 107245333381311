.headerTop{
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
}
.topheaderbox{
    width: 100%;
    background-image: url(../../public/photo/toplogo.png);
    height: 25%;
    position: absolute;
    z-index: -100;
    background-size: cover;
    background-position: center;
}
.position{
    position: relative;
}
.zheder{
    z-index: 200;
}
.containerheder{
    max-width: 1320px;
}
.subheder{
    display: flex;
}
.logobox img{
    width: 170px;
    border: 3.5px solid rgb(182, 182, 182);
    border-radius: 50%;
}
.logobox{
    border:5px solid white;
    border-radius: 50%;
}
.HeaderEng{
    text-align: left;
}
.Headertext img{
    width:700px;
}
.textdeconone{
    text-decoration: none;
}
.headersectw{
    display: flex;
    align-items: center;
    position: relative;
    padding-left:30px ;
}
.Menubar{
    display: flex;
}
.Menubar li{
    list-style-type: none;
}
.headermenu{
    position: absolute;
    bottom: 0px;
}
.menutext{
    text-decoration: none;
    color: rgb(95, 95, 95);
    padding: 2px 15px;
}
.involve{
    display: flex;
    font-size: 18px;
    font-weight: 500;
    color: white;
    background-color: #ff6d12;
    padding: 6px 25px;
    border-radius: 30px;
    position: relative;
    text-decoration: none;
    align-items: center;
}
.iconbox{
    display: flex;
    padding: 0px;
    margin: 0px;
}
.iconbox li{
    list-style-type: none;
    padding: 8px 5px;
}
.iconbox li a img{
    width: 28px;
}
.headerthreetw{
    display: flex;
    float: right;
    align-items: center;
}
.headerthreetwa{
    display: flex;
    float: right;
    align-items: center;
}
.toglebutton{
    display: none;
}
.logotext2{
    display: none;
}
.iconboxa{
    display: flex;
    padding: 0px;
    margin: 0px;
}
.alicenter{
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconboxa li{
    list-style-type: none;
    padding: 8px 5px;
}
.iconboxa li a img{
    width: 28px;
}
.justifix{
    display: flex;
    justify-content: space-between;
}
@media (max-width:1200px){
    .headerthreetw{
        display: none;
    }
}
@media (max-width:992px){
    .headerTop{
        display: block;
    }
    .headerthreetw{
        display: flex;
        width: 100%;
    }
    .iconboxtop{
        padding: 6px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .iconbox{
        float: right;
    }
    .iconboxa{
        float: right;
    }
    .logobox img{
        width: 120px;
    }
    .Headertext img{
        width: 500px;
    }
    .headermenu{
        display: none;
    }
    .involve{
        padding: 5px 20px;
    }
    .iconbox{
        display: none;
    }
    .toglebutton{
        display: flex;
        border: none;
        background-color: rgba(255, 255, 255, 0);
        align-items: center;
    }
    .toglebutton img{
        width: 28px;
    }
    .dispacherfix{
        display: block;
    }
}
@media (max-width:768px){
    .position{
        padding: 0px;
        margin: 0px;
        width: 100%;
        padding-left: 0px;
        margin-left: 0px;
        --bs-gutter-x: 0rem;
    }
    .logobox{
        border: 0px;
    }
    .img{
        border: 3px solid white;
        border-radius: 50%;
    }
    .topheaderbox{
        height: 50px;
    }
    .headerTop{
        justify-content: center;
    }
    .subheder{
        display: block;
    }
    .subheder{
        width: 100%;
        justify-content: center;
    }
    .logobox{
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .logobox img{
        width: 95px;
        border: 1px solid rgb(95, 95, 95);
    }
    .headersectw{
        padding-top: 10px;
        padding-left: 0px;
        padding-left: 10px;
        width: 100%;
    }
    .Headertext img{
        width: 100%;
    }
    .logotext2{
        display: block;
    }
    .firsttext{
        display: none;
    }
    .fixwidis{
        width: auto;
        display: block;
    }
}


.headeroffbox{
    position: relative;
    width: 100%;
}
.hiderofficeover{
    width: 100%;
    overflow: hidden;
    /* overflow-x: scroll; */
}
.headeroffice{
    width: 200%;
    display: flex;
}
.headerofficepast{
    width: 150%;
    display: flex;  
}
.headerofficemem{
    width: 266.6666%;
    display: flex;
}
.officebearphoto{
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}
.officebearphoto img{
    width: 200px;
    background-position: center;
    background-size: cover;
    margin: 20px 0px;
}
.officebearphotoa img{
    max-width: 450px;
    margin: 20px 0px;
}
.officebearphotoa{
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}
.widthheder{
    width: 33.3333%;
    padding: 10px;
}
.widthheaderbody{
    border: 2px solid #e4f962;
    border-radius: 20px;
    box-shadow: 5px 10px 5px rgb(237, 237, 237);
}
.backheder{
    background: linear-gradient(rgba(255, 255, 255, 0.249),rgba(255, 255, 255, 0.195)),url(../../public/photo/toplogo.png);
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    height: 50%;
    position: absolute;
    z-index: -10;
    background-position: center;
    background-size: cover;
}
.backhedera{
    background: linear-gradient(rgba(255, 255, 255, 0.249),rgba(255, 255, 255, 0.195)),url(../../public/photo/toplogo.png);
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    height: 50%;
    position: absolute;
    z-index: 0;
    background-position: center;
    background-size: cover;
}
.loadhi{
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
}
::-webkit-scrollbar {
    display: none;
}
.Bearname{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}
.officebearphoto img{
    border-radius: 50%;
}
.Bearname h4{
    padding: 10px 0px 20px;
    margin-bottom: 0px;
    border-bottom: 2px solid rgb(182, 182, 182);
}
li{
    list-style-type: none;
}
.officon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
}
.officon li img{
    width: 22px;
    margin: 4px;
}
.officon li{
    display: flex;
    align-items: center;
}
.officeinfo{
    display: flex;
    justify-content: space-around;
    padding: 25px 0px;
    align-items: center;
}
.post h6{
    margin-bottom: 0px;
    color: blue;
}
.rightbutton{
    position: absolute;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0);
    top: 50%;
    border: 0px;
    right: 0px;
}
.leftbutton{
    position: absolute;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0);
    top: 50%;
    border: 0px;
    left: 0px;
}
.rightbutton img{
    width: 20px;
}
.leftbutton img{
    width: 20px;
}
.section{
    padding: 70px 0px;
}
.cetifier{
    padding:20px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
} 
.textboxwel{
    display: flex;
}
.welbanner{
    background-image: url(../../public/photo/cta-two-bg.jpg);
}
.welboxtwo{
    display: flex;
    align-items: center;
    justify-content: center;
}
.welboxone{
    color: white;
}
.innerwelbox{
    background-color: rgb(18, 18, 18);
    color: white;
    border-radius: 40px;
}
.innerwelbox:hover{
    background-color: white;
    color: #ff6d12;
}
.innerwelbox h6{
    margin: 0px;
    padding: 20px 40px;
}
.cetifierbox{
    display: flex;
    align-items: center;
    justify-content: center;
}
.sectionbott{
    padding-bottom: 70px;
}
.widthbanner{
    background-image:linear-gradient(rgba(0, 0, 0, 0.322),rgba(0, 0, 0, 0.377)), url(../../public/photo/backold.jpg);
    background-attachment: fixed;
    display: flex;
    align-items: center;
    color: white;
    text-align: center;
    height: 600px;
    background-color: rgba(0, 0, 0, 0.197);
}
.lifegift{
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.489);
}
.eventbox{
    position: relative;
    width: 100%;
}
.imgeven img{
    width: 700px;
    position: absolute;
    z-index: -1;
}
.texteventone{
    width: 700px;
    float: right;
    margin-top: 100px;
    padding: 50px;
    background-color: white;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    border: 1px solid rgb(224, 224, 224);
}
.textofeven{
    border-bottom: 2px solid gray;
}
.buttonevenmang{
    padding: 20px 30px;
    background-color: #ff6d12;
    color: white;
    margin-top: 20px;
    text-align: center;
}
.imgdisplayer{
    display: flex;
    align-items: center;
}
.oneimg img{
    width: 50px;
}
.twoimg img{
    width: 220px;
}
.dispadder{
    display: flex;
    justify-content: space-between;
}
.buttoneven{
    padding: 10px 20px;
    background-color: #ff6d12;
    color: white;
    align-items: center;
}
.buttoneven h6{
    margin-bottom: 0px;
}
.buttoncenter{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.headerofficeevent{
    display: flex;
    width: 1000%;
}
.orange{
    color: #ff6d12;
}
.disgaller{
    display: flex;
}
.disgallerydis{
    transition: 1s;
}
.galimgcon img{
    width: 100%;
}
.conevent{
    display: flex;
    text-align: center;
}
.conevent h5{
    line-height: 28px;
    font-size: 18px;
    margin-bottom: 20px;
}
.coneventa h5{
    line-height: 25px;
    font-size: 16px;
    margin-bottom: 18px;
}
.evenpm-2 h1{
    margin-bottom: 0px;
}
.rightbuttona{
    position: absolute;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0);
    border: 0px;
    top: 45%;
    display: flex;
    align-items: center;
    right: 0px;
}
.rightbuttona img{
    width: 20px;
}
.leftbuttona{
    position: absolute;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0);
    border: 0px;
    top: 45%;
    display: flex;
    align-items: center;
    left: 0px;
}
.leftbuttona img{
    width: 20px;
}
.imgboxev{
    width: 100%;
    position: relative;
}
.rowgaleven{
    width: 100%;
    position: relative;
    overflow: hidden;
}
.vedioscroll{
    width: 100%;
    position: relative;
    overflow: hidden;
    overflow-x: scroll;
}
.vedioscrolla{
    width: 100%;
    position: relative;
    overflow: hidden;
}
.imgplus{
    position: absolute;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.847);
    z-index: 2;
    border-radius: 13px;
    margin-left: 30px;
    margin-bottom: 10px;
    margin-top: 130px;
    opacity: 0;
    transition-duration: 0.5s;
}
.imgboxevent{
    display: flex;
}
.imgboxevent:hover .imgplus{
    margin-top: 30px;
    opacity: 1;
}
.gallhomebu{
    display: flex;
    padding: 12px 40px;
    background-color: #ff6d12;
    color: 0px;
    color: white;
    text-decoration: none;
    align-items: center;
}
.gallhomebu h5{
    margin: 0px;
}
.eventbut{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-top: 30px;
}
.homeinfoim{
    background-image: url(../../public/photo/testimonials-two-shape.png);
    width: 100%;
    background-position: center;
    padding: 30px;
    margin: 40px;
    background-repeat: no-repeat;
    background-size: cover;
}
.homeinfoim img{
    width: 100%;
    border-radius: 50%;
}
.painfo{
    box-sizing: border-box;
    display: flex;
    padding: 30px;
}
.homeinsl{
    background-color: rgb(38, 38, 38);
    padding: 70px 0px;
    position: relative;
}
.contenhomesl h2{
    color: #ff6d12;
}
.contenhomesl h3{
    color: white;
}
.conouter{
    display: flex;
    align-items: center;
}
.dotinner{
    padding-left: 0px;
    display: flex;
    right: 0px;
}
.dothoimg{
    width: 20px;
    margin: 2px;
}
.dothoimg img{
    width: 100%;
    background-color: white;
    border-radius: 50%;
}
.dotouter{
    position: absolute;
    top: 70%;
    right: 10%;
    display: flex;
    justify-content: right;
}
.overnuminfo{
    width: 100%;
    overflow: hidden;
}
.disnuminfo{
    display: flex;
    width: 400%;
}
.rowwid{
    width: 100%;
    display: flex;
}
.imgacti{
    width: 100%;
}
.actimgebox{
    overflow: hidden;
    margin: 5px;
}
.imgacti:hover{
    transform: scale(1.1);
    transition: 1s;
}
.actisection{
    padding: 50px 0px;
}
.actiinfobox{
    padding: 20px 0px;
    text-align: center;
    line-height: 28px;
    height: 238px;
}
.actiinfobox h4{
    color: #ff6d12;
    text-shadow: 2px 2px 2px #ff6d1273;
}
.actiinfobox h5{
    font-size: 18px;
    line-height: 28px;
    color: rgb(78, 78, 78);
}
.actibody{
    background-color: rgb(246, 245, 245);
    padding: 10px 8px;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(128, 128, 128, 0.271);
}
.rowmoreac{
    display: flex;
    justify-content: center;
    text-decoration: none;
}
.rowmobu{
    text-decoration: none;
    margin-bottom: 20px;
}
.rowmobu h5{
    margin-bottom: 0px;
    padding: 8px 30px;
    background-color: #ff6d12;
    color: white;
    box-shadow: 3px 3px 3px #ff6d1251;
}
.addinfob h6{
    color: rgb(68, 68, 68);
    line-height: 22px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.544);
}
.addinfob{
    width: 400px;
    position: absolute;
    text-align: center;
    background-color: rgb(227, 227, 227);
    padding: 0px 50px;
    border: 1px solid rgb(207, 207, 207);
    box-shadow: 5px 5px 5px rgba(79, 79, 79, 0.23);
}
.addinfob h5{
    margin-top: 24px;
    margin-bottom: 5px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.544);
}
.followali{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.mapofhome{
    width: 100%;
    height: 600px;
    padding-top: 50px;
}
.sechomap{
    margin-top: 30px;
}
.imagetagho{
    position: absolute;
    z-index: 2;
    padding: 12px 20px;
    background-color: #ff6d12;
    color: white;
    display: flex;
    align-items: center;
}
.imagetagho h6{
    margin-bottom: 0px;
}
.pade{
    padding: 3px;
}
.pade h5{
    font-size: 18px;
    color: black;
}
.vedieosize{
    aspect-ratio: 1/0.58;
    width: 100%;
    position: relative;
}
.imgve{
    width: 100%;
    position: absolute;
    z-index: 1;
    background-color: #ff6d1200;
    aspect-ratio: 1/0.58;
}
.twoarr{
    display: none;
}
.vediooter{
    display: block;
    width: 100%;
}
.vedioinner{
    display: flex;
    width: 100%;
}
.vedioarrow{
    background-color: rgba(255, 255, 255, 0);
    border: 0px;
}
.vedioarrow img{
    width: 20px;
}
.vearrinn{
    display: flex;
    justify-content: center;
}
.homeinfole{
    width: 150%;
}
.footer{
    background-color: rgb(24, 24, 24);
    padding: 70px 0px;
}
.footlogo{
    width: 100%;
    display: flex;
    justify-content: center;
}
.footlogo img{
    width: 100px;
    margin-bottom: 8px;
    border-radius: 50px;
    border: 3px solid rgb(207, 207, 207);
}
.footloco img{
    width: 100%;
}
.footimgbox{
    box-sizing: border-box;
    background-color: white;
    display: flex;
    justify-content: center;
}
.footinnerlogo{
    border: 4px solid rgb(207, 207, 207);
    padding: 10px 3px;
}
.footinfocon{
    margin-top: 20px;
    padding: 5px;
    color: white;
}
.footinfoic{
    display: flex;
    justify-content: center;
}
.secexplotitt{
    display: flex;
    width: 100%;
    justify-content: center;
    color: white;
    margin-bottom: 10px;
}
.secexplotitt h3::after{
    align-items: center;
    justify-content: center;
    background-color: white;
    display: flex;
    margin-top: 10px;
    content: ' ';
    display: flex;
    height: 3px;
    width: 60%;
}
.foseli{
    text-decoration: none;
    color: white;
}
.foseli h6{
    margin-bottom: 14px;
}
.fotthph img{
    width: 25px;
    padding: 5px;
    background-color: #ff6d12;
    height: 25px;
    margin-right: 10px;
} 
.forthphn{
    display: flex;
    align-items: center;
}
.forthphn h6{
    margin-bottom: 0px;
    color: white;
    line-height: 26px;
}
.fothinp{
    display: flex;
}
.fothin{
    margin-bottom: 18px;
}
.facebooklink{
    width: 200px;
    height: 100px;
}
.hosincol h2{
    color: #ff6d12;
    font-weight: 700;
}
.bannerone{
    background-image:linear-gradient(rgba(255, 255, 255, 0.955), rgba(255, 255, 255, 0.949)), url(../../public/photo/bannerone.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: -14;
}
.alicenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
.big{
    display: block;
}
.small{
    display: none;
}


/* Fix logo */
.fiximg img{
    width: 120px;
}
.botomfix{
    background-image:radial-gradient(rgba(255, 255, 255, 0.774),rgba(255, 255, 255, 0.785)), url(../../public/photo/toplogo.png);
    background-size: cover;
    background-position: center;
    box-shadow: 0px 5px 5px 0px rgba(128, 128, 128, 0.242) ;
    position: fixed;
    transition: 2s;
    z-index: 300;
    width: 100%;
    background-color: white;
}
.fixtest{
    font-size: 16px;
    font-weight: 600;
    color: black;
}

/* banner */
.banner{
    width: 100%;
    height: 300px;
    background-position: center;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
}
.innerboxbanner{
    width: 100%;
    display: flex;
    position: absolute;
    justify-content: center;
    bottom: 50px;
}
.linkbanner{
    color: #ff6d12;
    margin-right: 10px;
    text-decoration: none;
}
.bannerslce{
    color: white;
}
.bannernamme{
    margin-left: 10px;
    color: #ff6d12;
}
.contactbanner{
    background-image:linear-gradient(rgba(0, 0, 0, 0.258),rgba(0, 0, 0, 0.367)), url(../../public/photo/contactbanner.jpg);
}

/* form */
.former{
    padding: 20px;
    width: 100%;
    margin: 0px;
}
.inputer{
    margin: 5px;
    height: 70px;
    width: 100%;
    padding-left: 20px;
    border-radius: 10px;
    border: 2px solid black;
    background-color: rgb(237, 237, 237);
}
.areaer{
    margin: 5px;
    height: 140px;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid black;
    background-color: rgb(237, 237, 237);
}
.outersub{
    display: flex;
    width: 100%;
    justify-content: center;
}
.submiter{
    padding: 20px 30px;
    display: flex;
    position: relative;
    width: auto;
    background-color: #ff6d12;
    border-radius: 26px;
    border: 0px;
}
.submiter h6{
    margin-bottom: 0px;
    color: white;
}
.aboutbanner{
    background-image:linear-gradient(rgba(0, 0, 0, 0.258),rgba(0, 0, 0, 0.367)), url(../../public/photo/aboutusbanner.jpg);
}
.officebanner{
    background-image:linear-gradient(rgba(0, 0, 0, 0.258),rgba(0, 0, 0, 0.367)), url(../../public/photo/officebear.jpg);
}
.selectoffice{
    padding:18px 8px;
    text-align: center;
    text-decoration: none;
}
.selectoffice h5{
    margin-bottom: 0px;
}
.checkright{
    display: none;
}
.activitybanner{
    background-image:linear-gradient(rgba(0, 0, 0, 0.258),rgba(0, 0, 0, 0.367)), url(../../public/photo/activitybanner.jpg);
}
.eventbanner{
    background-image:linear-gradient(rgba(0, 0, 0, 0.258),rgba(0, 0, 0, 0.367)), url(../../public/photo/675be6ef621f1.jpg) ;
}
.paddacti img{
    width: 100%;
}
.textseconone{
    text-decoration: none;
}
.fontofmobli h5{
    color: black;
    font-weight: 700;
}
.mobmenusec{
    position: fixed;
    display: none;
    width: 100%;
    height:auto;
    z-index: 320;
    background-color: white;
    transition: 2s;
}
.secmobcon{
    width: 100%;
    height: 270px;
    overflow: hidden;
    overflow-y: scroll;
    background-color: white;
}
.backbutton{
    width: 100%;
    border: 0px;
}
.eventselect{
    display: block;
}
.loadmore{
    width: 100%;
    display: flex;
    justify-content: center;
}
.loadbut{
    padding: 10px 20px;
    background-color: #ff6d12;
    border: 0px;
}
.loadbut h6{
    margin-bottom: 0px;
    color: white;
}

@media (max-width:1400px){
    .imgeven img{
        width: 600px;
    }
    .texteventone{
        width: 600px;
    }
}
@media (max-width:1200px){
    .imgeven img{
        width: 550px;
    }
    .texteventone{
        width: 550px;
    }
    .homeinfoim{
        padding: 20px;
        margin: 20px;
    }
    .actiinfobox{
        height: 190px;
    }
}
@media (max-width:992px){
    .headeroffice{
        width: 300%;
    }
    .headerofficepast{
        width: 150%;
    }
    .headerofficemem{
        width: 400%;
    }
    .widthheder{
        width: 50%;
    }
    .welboxone{
        text-align: center;
        padding-bottom: 20px;
    }
    .imgeven img{
        width: 100%;
        position: relative;
        margin-top: 0px;
    }
    .texteventone{
        width: 100%;
        position: relative;
        margin-top: 0px;
    }
    .texteventone{
        border-radius: 0px 0px 10px 10px;
    }
    .homeinfoim{
        padding: 16px;
        margin: 5px;
    }
    .actiinfobox{
        height: 230px;
    }
    .fixheadermenu{
        display: none;
    }
    .big{
        display: none;
    }
    .small{
        display: block;
    }
    .eventselect{
        display: none;
    }
}
@media (max-width:768px){
    .headeroffice{
        width: 600%;
    }
    .headerofficepast{
        width: 300%;
    }
    .headerofficemem{
        width: 800%;
    }
    .widthheder{
        width: 100%;
        padding: 10px 0px;
    } 
    .officeinfo{
        display: block;
        justify-content: center;
        text-align: center;
    }
    .officon{
        padding-left: 0px;
        padding-top: 10px;
    }
    .homeinfoim{
        max-width: 270px;
    }
    .painfo{
        display: flex;
        justify-content: center;
    }
    .contenhomesl{
        text-align: center;
    }
    .dotouter{
        position: relative;
        top: 20px;
        display: flex;
        right: 0px;
        justify-content: center;
    }
    .actiinfobox{
        height: auto;
    }
    .rowwid{
        width: 100%;
        display: block;
    }
    .pade{
        padding: 0px;
    }
    .twoarr{
        display: block;
    }
    .checkright{
        display: block;
    }
}
@media (max-width:570px){
    .former{
        padding: 0px;
    }
    .texteventone{
        padding: 10px;
    }
    .oneimg img{
        width: 30px;
    }
    .twoimg img{
        width: 100px;
    }
    .buttoneven{
        padding: 8px 14px;
    }
    .addinfob{
        position: relative;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
    .imagetagho{
        padding: 7px 15px;
    }
    .loadhi img{
        width: 80%;
    }
}
@media (max-width:450px){
    .dispacherfix{
        display: none;
    }
}